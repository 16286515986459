import { useState } from 'react';
import { Chip, ChipProps, Stack } from '@mui/material';
import { SiteDetail, useDeleteSiteUserForAllSites } from 'api/site';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from 'component/new_design/base/Table';
import { TableColumnType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from '../base/EmptyState';
import InviteUser from './InviteUser';
import { useDeleteUser, useReinviteUser, useUserChangeTwoAuth, User, useGetUsers } from 'api/users';
import { useUserResendAllSitesPendingInvite } from 'api/auth';
import { useNavigate } from 'react-router-dom';

// icons
import UserIcon from '../icons/User.svg?react';
import UserThree from '../icons/UserThree.svg?react';
import LockClosed from '../icons/LockClose.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { linkHelper } from 'linkHelper';

// TODO - get this from generated types once the type is available

interface Props {
  readonly siteDetails?: SiteDetail;
}

/**
 * eventually this will include name and avatar URL, will bring up to @dkoston
 */
function NameCell({ user }: { readonly user: User }) {
  if (user.firstname && user.lastname) {
    return `${user.firstname} ${user.lastname}`;
  }
  return user.firstname ?? user.lastname;
}

function TwoFACell({ user }: { readonly user: User }) {
  const { t } = useTranslation();
  if (user.type === 'account') {
    return user.twofactor_enabled ? t('enabled') : t('disabled');
  }
  return '';
}

function AccessLevelCell({ user }: { readonly user: User }) {
  const { t } = useTranslation();
  if (user.type === 'account') {
    return t('account_user');
  }
  if (user.sites) {
    return t('site_count', {
      count: user.sites.length,
    });
  }
  return '';
}

export default function UserList() {
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const tableState = usePaginatedListState({ urlStatePrefix: 'users' });
  const { mutateAsync: deleteAccountUser } = useDeleteUser();
  const { mutateAsync: deleteSiteUser } = useDeleteSiteUserForAllSites();
  const { mutateAsync: reinviteAccountUser } = useReinviteUser();
  const { mutateAsync: resendInvite } = useUserResendAllSitesPendingInvite();
  const { mutateAsync: changeTwoFactorAuth } = useUserChangeTwoAuth();
  const { isLoading, data } = useGetUsers();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns: (TableColumnType<User> | null)[] = [
    {
      label: t('name'),
      key: 'firstname',
      renderValue: user => <NameCell user={user} />,
      sortable: true,
    },
    {
      label: t('email'),
      key: 'email',
      sortable: true,
    },
    {
      label: t('access_level'),
      key: 'type',
      renderValue: user => <AccessLevelCell user={user} />,
      sortable: false,
    },
    {
      label: t('2fa'),
      key: 'type',
      renderValue: user => <TwoFACell user={user} />,
      sortable: false,
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: user => {
        if (!user.status) {
          return '';
        }

        let color: ChipProps['color'] = 'success';

        if (user.status === 'invited') {
          color = 'warning';
        }

        return <Chip variant="tag" label={t(`statuses.${user.status}`)} color={color} />;
      },
      sortable: true,
    },
  ];

  const handleConfirmDelete = async () => {
    if (!userToDelete || !userToDelete.id) {
      throw new Error('User ID is missing');
    }

    if (userToDelete.type === 'account') {
      await deleteAccountUser({ userId: userToDelete.id });
    } else {
      // need to figure this out since site users take numbers for ids, also we need an api to delete all the site associations
      await deleteSiteUser({ userId: userToDelete.id });
    }
    enqueueSnackbar(t('user_removed_successfully'), {
      key: 'RemoveUser',
      variant: 'success',
    });
    setUserToDelete(null);
  };

  return (
    <>
      {userToDelete ? (
        <ConfirmationDialog
          title={t('user_delete')}
          description={
            <Trans
              i18nKey="user_delete_confirmation_description"
              values={{ user: userToDelete.email }}
            />
          }
          confirmColor="error"
          onClose={() => setUserToDelete(null)}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
      <Table
        title={
          <Stack direction="row" alignItems="center">
            {t('users')}
          </Stack>
        }
        label={t('users_table')}
        rowActions={[
          {
            onClick: async user => {
              await changeTwoFactorAuth({ userId: user.id ?? '', twofa: true });
              enqueueSnackbar(t('two_factor_authentication_enabled'), {
                key: 'twoFactorAuth',
                variant: 'success',
              });
            },
            icon: <LockClosed />,
            label: t('force_2factor_auth'),
            hidden: user => user.type === 'account' && !user.twofactor_required,
          },
          {
            onClick: user => {
              if (user.type === 'account') {
                navigate(
                  linkHelper.newDesign.settings.users.account.profile.getLink({
                    userId: user.id ?? '',
                  })
                );
              } else {
                navigate(
                  linkHelper.newDesign.settings.users.site.profile.getLink({
                    userId: user.id ?? '',
                  })
                );
              }
            },
            inline: true,
            label: t('view'),
          },
          {
            onClick: async user => {
              if (user.type === 'account') {
                await reinviteAccountUser({ userId: user.id ?? '' });
              } else {
                await resendInvite({
                  userId: user.id ?? '',
                });
              }
              enqueueSnackbar(t('invite_resent_successfully'), {
                key: 'InviteUser',
                variant: 'success',
              });
            },
            inline: true,
            label: t('resend'),
          },
          {
            // TODO - we dont have this API
            onClick: () => {},
            icon: <UserIcon />,
            label: t('promote_to_account_user'),
            hidden: user => user.type === 'account',
          },
          {
            icon: <DeleteDustbin />,
            label: t('remove'),
            onClick: user => {
              setUserToDelete(user);
            },
            hidden: user => user.status !== 'active',
            color: 'reds.500',
          },
        ]}
        columns={columns}
        data={data?.result ?? []}
        totalRowCount={data?.metadata?.total ?? 0}
        isLoading={isLoading}
        state={tableState}
        enablePagination
        enableSearch
        searchPlaceholder={t('search_users')}
        emptyState={
          <EmptyState icon={<UserThree />} title={t('no_data_yet')} action={<InviteUser />} />
        }
        rowIdKey="email"
      >
        <InviteUser />
      </Table>
    </>
  );
}
